export default class HandleDiscount {
  constructor() {
    this.start = 0
    this.current = null
    this.increment = 1
    this.end = 5
    this.duration = 1000
    this.triggered = false
    this.elements = {
      input: '.js--discount',
    }
    this.current = this.start

    this.setEvents()
    this.setCount(this.current)
  }

  setEvents() {
    $(window).on(
      'resize scroll',
      function () {
        if ($(this.elements.input).isFullyInViewport()) {
          if (this.triggered === false) {
            this.updateCount()
          }
          this.triggered = true
        }
      }.bind(this)
    )
  }

  updateCount() {
    var inv = setInterval(
      function () {
        if (this.current <= this.end) {
          this.setCount(this.current)
          this.current += this.increment
        } else {
          clearInterval(inv)
        }
      }.bind(this),
      this.duration / this.end
    )
  }

  setCount(number) {
    $(this.elements.input + ' span').html(number)
  }
}
