export default class ShowRedirectPopup {
  constructor() {
    var gtmredirect = $('[data-redirectpopup="gtmredirect"]')

    if (gtmredirect.length) {
      var popupredirect = true

      if (popupredirect) {
        gtmredirect.attr('data-redirectpopupstate', 'active')
      }

      $('.js--redirect-popup').on('click', function (e) {
        e.preventDefault()
        closePopup()
      })

      function closePopup() {
        var uri = window.location.toString()
        var clean_uri = uri.substring(0, uri.indexOf('?'))

        window.history.replaceState({}, document.title, clean_uri)
        location.reload()
      }
    }
  }
}
