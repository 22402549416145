export default class HandleLogo {
  constructor() {
    this.path = null
    this.length = null
    this.maxLength = null
    this.r = 1.01
    this.animationSpeed = 1

    this.initialize()
  }

  initialize() {
    this.path = document.querySelector('#Path-2')
    this.length = this.maxLength = this.path.getTotalLength()

    this.path.style.transition = this.path.style.WebkitTransition = 'none'
    this.path.style.strokeDasharray = this.length + ' ' + this.length
    this.path.style.strokeDashoffset = this.length
    // Trigger a layout so styles are calculated & the browser
    // picks up the starting position before animating
    this.path.getBoundingClientRect()

    this.animate()
    //         path.style.transition = path.style.WebkitTransition =
    //             'stroke-dashoffset 2s ease-in-out';
    // // Go!
    //         path.style.strokeDashoffset = '0';
  }

  animate() {
    if (this.length > this.animationSpeed) {
      this.length -= this.animationSpeed
      this.path.style.strokeDashoffset = this.length
      requestAnimationFrame(this.animate.bind(this))
    }
    if (this.length > this.maxLength / 2) {
      this.animationSpeed = Math.min(this.animationSpeed * (this.r * 2), 15)
    } else {
      this.animationSpeed = Math.max(this.animationSpeed / this.r, 5)
    }
  }
}
