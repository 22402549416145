export default function () {
  var centerSlider = $('.js--slider-center')
  let wannahaveSlider = $('.js--slider-wannahave')
  let inspirationSlider = $('.js-inspiration-slider')
  if (inspirationSlider.length) {
    $('.js-inspiration-slider').slick({
      centerMode: true,
      centerPadding: '40px',
      slide: '.inspiration-style__slider-item',
      swipe: true,
      autoplay: false,
      slidesToShow: 2,
      arrows: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            arrows: false,
            swipe: true,
            centerMode: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            arrows: false,
            swipe: true,
            centerMode: true,
          },
        },
      ],
    })
  }

  if (centerSlider.length) {
    $('.js--slider-center').slick({
      centerMode: true,
      centerPadding: '30px',
      slide: '.slider__item',
      swipe: false,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 300,
      arrows: true,
      slidesToShow: 1,
      variableWidth: true,
      infinite: true,
      initialSlide: 3,
    })
  }

  if (wannahaveSlider.length) {
    $('.js--slider-wannahave').slick({
      slide: '.wannahave-slider__item',
    })
  }
}
