export default class HandleName {
  constructor(name) {
    this.elements = {
      input: '.js--setSession',
      label: '.js--getName',
      startTitle: '.js--nametitle-noname',
      title: '.js--nametitle',
    }

    this.setEvents()
    this.name = name
    this.typeWriter = this.typeWriter.bind(this)
  }

  setEvents() {
    $(this.elements.input).on(
      'change',
      function (e) {
        e.preventDefault()
        this.name = $(this.elements.input).val()
        this.postData()
        this.renderName()
        // $([document.documentElement, document.body]).animate({
        //     scrollTop: $(this.elements.label).offset().top - 210
        // }, 2000);
      }.bind(this)
    )
  }

  postData() {
    $.ajax({
      method: 'POST',
      url: '/ajax/name/',
      data: { name: this.name },
    })
  }

  renderName() {
    $(this.elements.label).each(
      function (i, e) {
        this.write(e)
        $(this.elements.title).removeClass('hidden')
        $(this.elements.startTitle).addClass('hidden')
      }.bind(this)
    )
  }

  write(e) {
    $(this.elements.label).addClass('typing')
    this.typeWriter(this.name, 0, e)
  }

  done() {
    setTimeout(() => {
      $(this.elements.label).removeClass('typing')
    }, 1000)
  }

  typeWriter(text, n, e) {
    if (n < text.length) {
      $(e).text(text.substring(0, n + 1))
      n++
      setTimeout(() => {
        this.typeWriter(text, n, e)
      }, Math.floor(Math.random() * 200) + 100)
    } else {
      this.done()
    }
  }
}
