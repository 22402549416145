/**
 *    Toggle class
 *    Automatically finds DOM elements associated with this
 *    toggle and devides these into targets and toggles
 *    Class provides it's own event handling.
 *    @param { string } aIDa
 */
class Toggle {
  /**
   * # Note that child-classes need to call super() on the
   * first line inside their constructor
   */
  constructor() {
    // Declare variables
    this.pairs = {}

    // Call init methods
    this._setDOMReferences()
  }

  /**
   *    Sets all elements with an id to a certain state rather then toggling them
   *    @param { string } aID
   *    @param { string } aState
   */
  setState(aID, aState) {
    const elements = this.pairs[aID].targets.concat(this.pairs[aID].togglers)
    elements.forEach((aElement) => {
      aElement.setAttribute('data-state', aState)
    })
  }

  /**
   *    Toggles all target associated with this toggle
   *    Publicly available methods are not prefixed
   *    due to ES6 not supporting public methods yet.
   *    Public methods are always on the top of the class
   *    @param { string } aID
   */
  toggle(aID) {
    /**
     * # Leave a empty line if there are multiple lines in
     * # between the brackets.
     */
    const elements = this.pairs[aID].targets.concat(this.pairs[aID].togglers)
    elements.forEach((aElement) => {
      let state = aElement.getAttribute('data-state')
      state = state == 'passive' ? 'active' : 'passive'
      aElement.setAttribute('data-state', state)
    })
  }

  /**
   *    Gets all toggler objects from the DOM.
   *    Private methods are prefixed with an '_'
   *    due to ES6 not supporting private methods yet
   */
  _setDOMReferences() {
    // Build an array with all toggle-id's
    const elements = document.querySelectorAll('[data-togglerId]')
    elements.forEach((aElement) => {
      // Push a new entry if none exists yet for the id
      const id = aElement.getAttribute('data-togglerId')
      if (!this.pairs[id]) {
        this.pairs[id] = {
          togglers: [],
          targets: [],
        }
      }

      // Push the togglers and target elements to their relative pair
      let role = aElement.getAttribute('data-role')
      if (role == 'toggler') {
        aElement.addEventListener('click', () => {
          this.toggle(id)
        })
        this.pairs[id].togglers.push(aElement)
      } else if (role == 'togglee') {
        this.pairs[id].targets.push(aElement)
      }
    })
  }
}

export default Toggle
