// import kitchenOverview from './kitchenOverview';
import HandleName from './name'
import handleSliderFunction from './slider'
import fancyboxGalleryFunction from './fancyboxGallery'
import HandleFormSubmit from './formSubmit'
import HandleDiscount from './discount'
import HandleFilters from './filters'
import HandleToggle from './toggle'
import HandleMap from './maps'
import HandleLogo from './logo'
import ShowCookies from './nb-cookiepopup'
import ShowRedirectPopup from './redirect-popup'
import appAjax from './appAjax'
import KitchenOverview from './kitchenOverview'
import OutletOverview from './outletOverview'
import FilePondHandler from './filepond'
import HandleMasonry from './masonry'
import fixedScroll from './scrollFixed'
import HeaderHeight from './header-height'
import ContentToggle from './contentToggle'
import 'swiper/css';
import handleCaptchaFunction from './captcha'

class App {
  constructor() {
    require('./prototype-extensions')
    this.name = ''
    this.handleName()
    require('./polyfills.js')
    this.editmode = false
    new HandleToggle()
    new HandleLogo()

    /**
     * This can be turned on when it's approved.
     */
    this.handleFixedScroll()
    this.showCookies()
    this.ShowRedirectPopup()

    $.fn.isInViewport = function () {
      if ($(this).length) {
        var elementTop = $(this).offset().top
        var elementBottom = elementTop + $(this).outerHeight()
        var viewportTop = $(window).scrollTop()
        var viewportBottom = viewportTop + $(window).height()
        return elementBottom > viewportTop && elementTop < viewportBottom
      } else {
        return false
      }
    }

    $.fn.isFullyInViewport = function () {
      if ($(this).length) {
        var elementTop = $(this).offset().top
        var elementBottom = elementTop + $(this).outerHeight()

        var viewportTop = $(window).scrollTop()
        var viewportBottom = viewportTop + $(window).height()

        return elementTop >= viewportTop && elementBottom <= viewportBottom
      } else {
        return false
      }
    }
    this.setName = this.setName.bind(this)
  }

  slick() {
    if (this.editmode === false && $('.slider').length > 0) {
      $('.slider').slick()
    }
    App.handleName.initialize()
  }
  setName(name) {
    this.name = name
  }
  handleName() {
    new HandleName(this.name)
  }
  handleDiscount() {
    new HandleDiscount()
  }
  handleContentToggle() {
    console.log('handleContentToggle')
    const contentToggleElems = document.querySelectorAll('.js-toggle-content')

    if (contentToggleElems.length) {
      for (let i = 0; i < contentToggleElems.length; i++) {
        new ContentToggle(contentToggleElems[i])
      }
    }
  }
  showCookies() {
    new ShowCookies()
  }

  ShowRedirectPopup() {
    new ShowRedirectPopup()
  }

  handleGallery() {
    fancyboxGalleryFunction()
  }

  handleSlider() {
    handleSliderFunction()
  }

  ajax(options) {
    new appAjax(options)
  }

  handleFilters() {
    new HandleFilters()
  }
  handleFormSubmit() {
    new HandleFormSubmit()
  }
  handleMap() {
    new HandleMap()
  }
  handleMasonry() {
    new HandleMasonry()
  }
  handleKitchenOverview() {
    new KitchenOverview()
  }
  handleOutletOverview() {
    new OutletOverview()
  }
  handleFilePond(options) {
    new FilePondHandler(options)
  }
  handleFixedScroll() {
    const fixedScrollGrabber = document.querySelector('.js--fixed-scroll')
    if (fixedScrollGrabber !== null) {
      new fixedScroll(fixedScrollGrabber)
    }
  }
  handleHeaderHeight() {
    new HeaderHeight()
  }
  handleCaptcha(formId, responseId, googleKey) {
    handleCaptchaFunction(formId, responseId, googleKey);
  }
}

// Store local App object globally
window.App = new App()

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index
}
