HTMLFormElement.prototype.setEvents = function (e) {
  $(e).on(
    'submit',
    function (e) {
      // console.log(e);
      e.preventDefault() // cancel the actual submit
      this.postData(e.target)
    }.bind(this)
  )
}

HTMLFormElement.prototype.postData = function (form) {
  var f = $(form),
    data = f.serialize()
  // console.log(f.attr('action'));
  App.ajax({
    url: f.attr('action'),
    method: 'POST',
    data: data,
    success: function (response) {
      // console.dir(response);
      this.handleResponse(response)
    }.bind(this),
    fail: function (xhr, textStatus, errorThrown) {
      console.dir(textStatus)
    }.bind(this),
  })
}

HTMLFormElement.prototype.handleResponse = function (response) {
  var result = JSON.parse(response)
  if (result.success) {
    if (typeof result.redirect !== 'undefined' && result.redirect !== false) {
      this.redirectSuccess(result.redirect)
    } else {
      this.showSuccess()
    }
  } else {
    var errors = result.errors
    this.handleErrors(errors)
  }
}

HTMLFormElement.prototype.handleErrors = function (errors) {
  var _ = this
  var generalErrors = []
  _.elements.forEach(function (field) {
    field.error = false
  })

  errors.forEach(function (error) {
    if (typeof error.name === 'undefined') {
      console.log(error.msg)
      generalErrors.push('<p>' + error.msg + '</p>')
    } else {
      _.elements.forEach(function (field) {
        var errorName = _.name + '[' + error.name + ']'
        if (field.name === errorName) {
          field.error = true
        }
      })
    }

    if (generalErrors.length > 0) {
      var generalError = document.querySelector('.js-display-general-error')
      generalError.innerHTML = generalErrors
    }
  })

  this.showErrors()
}

HTMLFormElement.prototype.showErrors = function () {
  var yPositions = []
  this.elements.forEach(function (field) {
    // Is elment on form
    var el = field
    if (field.error === true) {
      // Loop recursively over the DOM tree to prevent
      // the scroll method breaking with different positionings
      var offset = el.offsetTop
      var _rec = function (element) {
        offset = element.offsetTop
        if (element.parentNode) {
          _rec(element.parentNode)
        }
      }

      yPositions.push(offset)
      _rec(el.parentNode)
      el.classList.add('error')
    } else {
      el.classList.remove('error')
    }
  })

  // Scroll to the first .error element on the page
  if (yPositions.length > 0) {
    var highest = yPositions.sort((a, b) => a - b)[0]
    window.scrollTo({
      top: highest, // Add some extra margin to the scroll position
      left: 0,
      behavior: 'smooth',
    })
  }
}

HTMLFormElement.prototype.showSuccess = function () {
  if (typeof dataLayer !== 'undefined') {
    dataLayer.push({ event: this.name + '_submit' })
  }
  this.style.display = 'none'
  this.notice = document.querySelector('.js--success')
  this.notice.style.display = 'block'
}

HTMLFormElement.prototype.redirectSuccess = function (location) {
  if (typeof dataLayer !== 'undefined') {
    dataLayer.push({
      event: this.name + '_submit',
      eventCallback: function () {
        window.location = location
      },
    })
  } else {
    window.location = location
  }
}
