import Masonry from 'masonry-layout'

export default class HandleMasonry {
  constructor() {
    if (document.readyState === 'complete') {
      this.handleMasonry()
    } else {
      window.addEventListener('load', () => {
        this.handleMasonry()
      })
    }
  }

  handleMasonry() {
    let masonryCollection = document.querySelectorAll('.masonry__wrapper')
    for (let i = 0; i < masonryCollection.length; i++) {
      console.log('all images are loaded')
      new Masonry(masonryCollection[i], {
        itemSelector: '.masonry__item',
        columWidth: 200,
        gutter: 12,
        percentPosition: true,
      })

      masonryCollection[i].classList.remove('is--loading')
      masonryCollection[i].classList.add('loaded')
    }
  }
}
