export default class fixedScroll {
  constructor(element) {
    ;(this.element = element), this._setDOMReferences()
    this.elementWidth = this.element.offsetWidth
    this.viewportWidth = window.innerWidth
    this.header = document.querySelector('.header')
  }

  _setDOMReferences() {
    window.addEventListener('resize', () => {
      this.onResize()
      this.setFixedPosition()
    })
    document.addEventListener('scroll', () => {
      ;(this.scrollPosition = window.pageYOffset), this.setFixedPosition()
    })
  }

  onResize() {
    this.elementWidth = this.element.offsetWidth
    this.viewportWidth = window.innerWidth
  }

  setFixedPosition() {
    if (
      this.scrollPosition >= this.header.offsetHeight + 55 &&
      this.element.getBoundingClientRect().top <= this.header.offsetHeight + 30 &&
      this.viewportWidth > 1024
    ) {
      this.element.style.position = 'fixed'
      this.element.style.top = this.header.offsetHeight + 30 + 'px'
      this.element.style.width = this.elementWidth + 'px'
      this.element.style.maxHeight = window.innerHeight - this.header.offsetHeight - 30 + 'px'
      this.element.style.overflow = 'scroll'
      this.element.parentElement.style.minHeight =
        (this.element.scrollHeight < window.innerHeight - this.header.offsetHeight
          ? this.element.scrollHeight
          : window.innerHeight - this.header.offsetHeight) + 'px'
    } else {
      this.element.style.position = 'relative'
      this.element.style.top = '0px'
      this.element.style.width = '100%'
      this.element.style.maxHeight = null
      this.element.style.overflow = null
      this.element.parentElement.style.minHeight = this.element.scrollHeight + 'px'
    }
  }
}
