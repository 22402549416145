/**
 * Import FilePond and FilePond Image Previewer
 */
import * as FilePond from 'filepond'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import fixedScroll from './scrollFixed'

export default class FilePondHandler {
  constructor(options) {
    this.container = document.querySelector('.filepond__container')
    // this.submitButton = document.querySelectorAll('button[type=submit]')[0];
    // this.submitButtonWrapper = document.querySelector('.js--fixed-scroll-button');
    // this.trigger = document.querySelector('.js--trigger');
    // const self = this;
    /**
     * In order to use the plugin, we first need to register it.
     */
    FilePond.registerPlugin(FilePondPluginImagePreview)
    FilePond.registerPlugin(FilePondPluginFileValidateType)
    FilePond.registerPlugin(FilePondPluginFileValidateSize)
    FilePond.registerPlugin(FilePondPluginImageExifOrientation)

    /**
     * Create a new instance of FilePond
     */
    const pond = FilePond.create(options)

    // window.addEventListener('scroll', () => {
    //     inViewportToggle();
    // });
    // window.addEventListener('touchend', () => {
    //     inViewportToggle();
    // });

    // function inViewportToggle () {
    //     window.requestAnimationFrame(() => {
    //         if (!$(self.submitButton).isFullyInViewport()) {
    //             self.submitButtonWrapper.classList.add('is--active');
    //         } else if ($(self.trigger).isFullyInViewport()) {
    //             self.submitButtonWrapper.classList.remove('is--active');
    //         } else {
    //             return false;
    //         }
    //     })
    // }
    pond.setOptions({
      imagePreviewTransparencyIndicator: 'grid',
      imagePreviewHeight: 200,
      onprocessfilestart() {
        // self.submitButton.setAttribute('disabled', 'disabled');
        // self.submitButton.innerHTML = "We zijn bezig met verwerken";
        const backButtons = document.querySelectorAll('.js--filepond-back')
        backButtons.forEach((button) => {
          button.style.display = 'none'
        })
      },

      onprocessfiles() {
        // self.submitButton.removeAttribute('disabled', 'disabled');
        // self.submitButton.innerHTML = "Ga verder";
        const backButtons = document.querySelectorAll('.js--filepond-back')
        backButtons.forEach((button) => {
          button.style.display = 'block'
        })
      },

      onupdatefiles() {
        // self.submitButton.removeAttribute('disabled', 'disabled');
        // self.submitButton.innerHTML = "Ga verder";
        const backButtons = document.querySelectorAll('.js--filepond-back')
        backButtons.forEach((button) => {
          button.style.display = 'block'
        })
      },
    })
    /**
     * Append the instance to the document.
     */
    pond.appendTo(this.container)
  }
}
