export default class HandleMap {
  constructor() {
    console.log('maar ik word wel geladen!')
    this.map = new google.maps.Map(document.getElementById('map'), {
      center: {
        lat: 52.3365921,
        lng: 5.2117199,
      },
      zoom: 7,
      styles: [
        {
          elementType: 'geometry',
          stylers: [
            {
              color: '#ffffff',
            },
          ],
        },
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              lightness: 20,
            },
            {
              gamma: 0.01,
            },
          ],
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              saturation: -31,
            },
            {
              lightness: -33,
            },
            {
              gamma: 0.8,
            },
            {
              weight: 2,
            },
          ],
        },
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#050505',
            },
          ],
        },
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#fef3f3',
            },
            {
              weight: '3.01',
            },
          ],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#0a0a0a',
            },
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#fffbfb',
            },
            {
              visibility: 'off',
            },
            {
              weight: '3.01',
            },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [
            {
              saturation: 30,
            },
            {
              lightness: 30,
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [
            {
              saturation: 20,
            },
          ],
        },
        {
          featureType: 'poi.attraction',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [
            {
              saturation: -20,
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              saturation: -30,
            },
            {
              lightness: 10,
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [
            {
              saturation: 25,
            },
            {
              lightness: 25,
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#686868',
            },
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#8d8d8d',
            },
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#353535',
            },
            {
              lightness: '6',
            },
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#ffffff',
            },
            {
              visibility: 'on',
            },
            {
              weight: '3.45',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#a1a1a1',
            },
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#292929',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.icon',
          stylers: [
            {
              hue: '#0006ff',
            },
            {
              saturation: -100,
            },
            {
              lightness: 15,
            },
            {
              gamma: 0.1,
            },
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#202020',
            },
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#ffffff',
            },
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'road.highway.controlled_access',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#d0d0d0',
            },
          ],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#999898',
            },
            {
              lightness: '2',
            },
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#383838',
            },
          ],
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#faf8f8',
            },
          ],
        },
        {
          featureType: 'water',
          stylers: [
            {
              lightness: -20,
            },
          ],
        },
      ],
    })
    this.duiven = { lat: 51.9631299, lng: 5.9955445 }
    this.denBosch = { lat: 51.6874605, lng: 5.3002167 }

    this.duivenMarker = new google.maps.Marker({ position: this.duiven, map: map })
    this.denBoschMarker = new google.maps.Marker({ position: this.denBosch, map: map })

    google.maps.event.addDomListener(window, 'resize', function () {
      this.map.setCenter(center)
    })
  }
}
