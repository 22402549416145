export default class ContentToggle {
  constructor(element) {
    this.container = element
    this.toggle = this.container.querySelector('.js-toggle-content-trigger')
    this.toggleContent = this.container.querySelector('.js-object-content')
    const offset = 300
    const bodyRect = document.body.getBoundingClientRect().top
    const elementRect = this.toggleContent.getBoundingClientRect().top
    const elementPosition = elementRect - bodyRect
    const offsetPosition = elementPosition - offset

    if (!this.toggle || this.toggleContent.scrollHeight <= this.toggleContent.clientHeight) {
      this.toggle.remove()
      return
    }

    console.log('container', this.container)
    console.log('toggle', this.toggle)
    console.log('toggleContent', this.toggleContent)

    this.toggle.addEventListener('click', () => {
      console.log('ok do stuff')

      if (this.toggleContent.classList.contains('object-content--open')) {
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        })
      }

      this.toggleContent.classList.toggle('object-content--open')
      this.toggle.classList.toggle('button-link-toggle--active')
    })
  }
}
