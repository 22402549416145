export default class HandleFormSubmit {
  constructor() {
    this.selector = '.js--form-container'

    var forms = document.querySelectorAll(this.selector)
    forms.forEach((e) => {
      this.initForm(e)
    })
  }
  initForm(e) {
    this.elements = {
      form: '',
      notice: '.js--success',
      fields: [],
    }
    this.postUrl = ''

    this.container = e
    this.form = e.querySelector('form')
    this.notice = this.container.querySelector(this.elements.notice)
    this.formName = this.form.getAttribute('name')
    this.postUrl = this.form.getAttribute('action')

    console.log('handled')
    ;[].slice.call(this.form.elements).forEach(
      function (field) {
        var name = field.getAttribute('name')
        var element = { name: name, field: field, error: false }
        this.elements.fields.push(element)
      }.bind(this)
    )
    this.form.setEvents(this.form)
  }
}
