export default function (formId, responseId, googleKey) {
  document.getElementById(formId).addEventListener('submit', (e) => {
    e.preventDefault();
    let form = e.target;
    grecaptcha.ready(function() {
      grecaptcha.execute(googleKey, {action: 'submit'}).then(function(token) {
        document.getElementById(responseId).value = token;
        form.submit();
      })
    });
  });
};
