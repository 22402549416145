import noUiSlider from 'nouislider'
export default class HandleFilters {
  /**
   * sliderStart and sliderEnd in function
   */

  constructor(sliderStart, sliderEnd) {
    this.slider = document.querySelector('.js--budget-slider')
    this.minInput = document.querySelector('.js--budget-min')
    this.maxInput = document.querySelector('.js--budget-max')
    this.changedClass = 'budget-input--changed'
    this.sliderStart = sliderStart
    this.sliderEnd = sliderEnd

    // Add a range of 100 in budget slider
    if (this.sliderStart > 1000) {
      this.sliderStart = 1000
    } else {
      this.sliderStart = 0
    }
    this.sliderEnd = this.sliderEnd + 100

    /**
     * All variables need 'this.' appended to them.
     */
    if (this.slider && this.minInput && this.maxInput) {
      noUiSlider.create(this.slider, {
        start: [sliderStart, sliderEnd],
        connect: true,
        tooltips: true,
        range: {
          min: 100,
          max: sliderEnd + 500,
        },
        format: {
          from: function (value) {
            return parseInt(value)
          },
          to: function (value) {
            return parseInt(value)
          },
        },
      })

      slider.noUiSlider.on('update', function (values, handle) {
        var value = values[handle]

        if (handle === 0) {
          minInput.value = value
          if (value != sliderStart) {
            minInput.classList.add(changedClass)
          }
        } else {
          maxInput.value = value
          if (value != sliderEnd) {
            maxInput.classList.add(changedClass)
          }
        }
      })

      slider.noUiSlider.on('end', function () {
        if (minInput.classList.contains(changedClass)) {
          minInput.dispatchEvent(new Event('change'))
        }
        if (maxInput.classList.contains(changedClass)) {
          maxInput.dispatchEvent(new Event('change'))
        }
      })

      minInput.addEventListener('change', function () {
        slider.noUiSlider.set([this.value, null])
      })

      maxInput.addEventListener('change', function () {
        slider.noUiSlider.set([null, this.value])
      })
    }
  }
}
