export default class appAjax {
  constructor(options) {
    this.xhr = null
    this.method = 'GET'
    this.url = ''
    this.urlPostfix = 'ajax=true'
    this.data = ''
    this.dataType = null
    this.async = true
    this.success = null
    this.error = null
    this.beforeSend = null
    this.contentType = null
    this.timeout = 0
    this.xhrTimeout = null
    this.cache = true

    this.setOptions(options)
    this.init()
    this.send()
  }
  setOptions(options) {
    for (var key in options) {
      if (options.hasOwnProperty(key)) {
        this[key] = options[key]
      }
    }
  }
  init() {
    this.xhr = new XMLHttpRequest()
    this.method = this.method.toUpperCase()
  }

  setHeaders() {
    if (this.contentType !== null) {
      this.xhr.setRequestHeader('Content-Type', this.contentType)
    } else if (this.method === 'POST') {
      this.xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
    }
    if (this.beforeSend !== null) {
      this.xhr = this.beforeSend(this.xhr)
    }
  }

  setOnload() {
    this.xhr.onload = function () {
      this.parseResponse(this.xhr.responseText)
      this.clearXhrTimeout()
    }.bind(this)
  }

  parseResponse(response) {
    if (this.xhr.status === 200) {
      if (this.success !== null) {
        if (this.dataType === 'json') {
          response = JSON.parse(response)
        }
        this.success(response)
      }
    } else if (this.error !== null) {
      this.error(response)
    }
  }

  setOnError() {
    if (this.error !== null) {
      this.xhr.onerror = function () {
        this.error(this.xhr.statusText)
      }.bind(this)
    }
  }

  parseData() {
    if (this.contentType == 'application/json' && typeof this.data === 'object') {
      return JSON.stringify(this.data)
    }
    var encodedString = ''
    if (typeof this.data === 'object') {
      for (var prop in this.data) {
        if (this.data.hasOwnProperty(prop)) {
          if (encodedString.length > 0) {
            encodedString += '&'
          }
          encodedString += encodeURI(prop + '=' + this.data[prop])
        }
      }
    } else {
      encodedString = this.data
    }
    return encodedString
  }

  setUrl() {
    if (this.cache === false) {
      this.urlPostfix = this.urlPostfix + '&_=' + Math.floor(Date.now() / 1000)
    }
    this.url += (this.url.match(/\?/) ? '&' : '?') + this.urlPostfix
  }

  open() {
    if (this.async) {
      this.xhr.open(this.method, this.url)
    } else {
      this.xhr.open(this.method, this.url, this.async)
    }
  }

  xhrSend() {
    this.xhr.send(this.parseData())
    this.setXhrTimeout()
  }

  xhrAbort() {
    this.xhr.abort()
    this.error('Timeout')
  }

  setXhrTimeout() {
    if (this.timeout > 0) {
      this.xhrTimeout = setTimeout(
        function () {
          this.xhrAbort()
        }.bind(this),
        this.timeout
      )
    }
  }

  clearXhrTimeout() {
    if (this.xhrTimeout !== null) {
      clearTimeout(this.xhrTimeout)
    }
  }

  send() {
    this.setUrl()
    this.open()
    this.setHeaders()
    this.setOnload()
    this.setOnError()
    this.xhrSend()
  }
}
